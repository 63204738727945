import cognitoAuth from '@/cognito'
import Axios from 'axios'


export default class EnrichAuth {

    constructor () {
        this.loggedInUser = null
    }
  
    login (username, password, cb) {
        console.log('enrichAuth: login start')
        /*eslint: no-unused-vars: "off"*/
        cognitoAuth.authenticate(username, password, (err, result, user, userAttributes) => {
            if (err) {
                //debugger;
                console.error('enrichAuth: login err')
                console.error('enrichAuth: login err: ' + err)
                cb(err)
                // this.error = err
                // this.loading = false
                // console.log("result: " + result);
            } else if (userAttributes) {
                cb(err, result, user, userAttributes)
            } else {
                console.log('enrichAuth: login: done')
                console.log('enrichAuth: login: user: ' + user)
                console.log('enrichAuth: login: username: ' + username)
                this.fetchUser(username, (user, err) => {
                    if (err) {
                        cb(err)
                    } else {
                        console.log('enrichAuth: login, user: ' + JSON.stringify(user))
                        this.fetchOrganisation(user.organisationId, (organisation, err) => {
                            if (err) {
                                cb(err)
                            } else {
                                cb(err, result, user, userAttributes)
                                console.log('enrichAuth: about to setLoggedInUser')
                                this.setLoggedInUser({
                                    user: user,
                                    organisation: organisation
                                })
                            }
                        });
                    }
                });
            }
        });
    }

    changePassword(user, newPassword, cb) {
        console.log('auth: changePassword - still needs to be written!!!')
    }

    completeNewPasswordChallenge(user, newPassword, cb) {
        console.log("auth: in changePassword...");
        cognitoAuth.completeNewPasswordChallenge(user, newPassword, (result, err) => { //username, newPassword, userAttributes
            if (err) {
                //debugger;
                console.log("Login: cb for completeNewPasswordChallenge, err.message: " + err.message);
                cb(null, err)
            } else {
                console.log('auth: changePassword, user: ' + JSON.stringify(user))
                let username = user.username
                console.log('auth: changePassword, newPassword: ' + newPassword)
                this.fetchUser(username, (user, err) => {
                    if (err) {
                        cb(err)
                    } else {
                        console.log('enrichAuth: changePassword, user: ' + JSON.stringify(user))
                        this.fetchOrganisation(user.organisationId, (organisation, err) => {
                            if (err) {
                                cb(err)
                            } else {
                                cb(err, result, user, userAttributes)
                                console.log('enrichAuth: about to setLoggedInUser')
                                this.setLoggedInUser({
                                    user: user,
                                    organisation: organisation
                                })
                            }
                        });
                    }
                });
                cb(result)
            }
        });
    }

    isLoggedIn(cb) {
        console.log('auth: isLoggedIn: ')
        cognitoAuth.isAuthenticated((err, authenticated) => {
            cb(err, authenticated)
            console.log('loggedIn: ' + this.loggedIn)
        })
    }

    logout(cb) {
        console.log('auth: about to log out...')
        cognitoAuth.logout()
        this.removeLoggedInUser()
        // isLoggedIn()
    }

    getLoggedInUser() {
        console.log('auth: getLoggedInUser: start')
        console.log('auth: getLoggedInUser: loggedInUser: ' + JSON.stringify(this.loggedInUser))
        if (this.loggedInUser == null) {
            if (localStorage.getItem('loggedInUser')) {
                try {
                    console.log('getLoggedInUser: get loggedInUser from local storage...')
                  this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
                  console.log('getLoggedInUser: got loggedInUser from local storage: ' + JSON.stringify(this.loggedInUser))
                } catch(e) {
                    console.error('getLoggedInUser: could not find loggedInUser in local storage')
                    localStorage.removeItem('loggedInUser');
                }
              }
        }
        console.log('auth: getLoggedInUser, about to return: ' + JSON.stringify(this.loggedInUser))
        return this.loggedInUser
    }

    setLoggedInUser(user) {
        console.log('auth: setLoggedInUser')
        console.log('auth: setLoggedInUser, user: ' + JSON.stringify(user))
        const parsed = JSON.stringify(user);
        localStorage.setItem('loggedInUser', parsed);
        this.loggedInUser = user
    }

    removeLoggedInUser() {
        localStorage.removeItem('loggedInUser');
        this.loggedInUser = null
    }
  
    fetchUser(username, cb) {
        const url = process.env.VUE_APP_CRM_API_ROOT + '/users';
        let params = {
            "username": username
        }
        console.log('url: ' + url)
        console.log('fetch Users, params: ' + JSON.stringify(params))
        console.log('fetch user')
        cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('User, axios token: ' + result)
                Axios.defaults.headers.common['Authorization'] = result;
                console.log('fetchUser, this.$http.defaults.headers.common was set')
                Axios({ method: 'get', url: url, params: params})
                    .then(response => {
                        console.log('fetch users, response received')
                        console.log('fetch users, response: ' + JSON.stringify(response.data))
                        let users = response.data
                        if (users.length > 1) {
                            cb(null, 'Too many users with username: ' + username)
                            return
                        }
                        if (users.length == 0) {
                            cb(null, 'User not found')
                            return
                        }
                        console.log('fetch users, user: ' + JSON.stringify(users[0]))
                        delete Axios.defaults.headers.common['Authorization']; 
                        cb(users[0])
                    })
                    .then(status => {
                        console.log('fetch users, axios status: ' + status)
                    })
                    .catch(err => {
                        console.log('fetch users, axios err: ' + err)
                        cb(null, err)
                    })
            }
        })

    }
    
    fetchOrganisation(organisationId, cb) {
        const url = process.env.VUE_APP_CRM_API_ROOT + '/organisations/' + organisationId;
        console.log('url: ' + url)
        console.log('fetch organisation')
        cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Organisation, axios token: ' + result)
                Axios.defaults.headers.common['Authorization'] = result;
                console.log('fetchOrganisation, this.$http.defaults.headers.common was set')
                Axios({ method: 'get', url: url})
                    .then(response => {
                        console.log('Organisation, axios response returned')
                        console.log('Organisation, axios response: ' + JSON.stringify(response.data))
                        let organisation = response.data
                        delete Axios.defaults.headers.common['Authorization']; 
                        cb(organisation)
                    })
                    .then(status => {
                        console.log('fetch organisation, axios status: ' + status)
                    })
                    .catch(err => {
                        console.log('fetch organisation, axios err: ' + err)
                        cb(null, err)
                    })
            }
        })

    }

}

// This installed CognitoAuth into our Vue instance
EnrichAuth.install = function (Vue, options) {
    Object.defineProperty(Vue.prototype, '$enrichAuth', {
      get () { return this.$root._enrichAuth }
    })
  
    // Vue.mixin({
    //   beforeCreate () {
    //     if (this.$options.enrichAuth) {
    //       this._enrichAuth = this.$options.enrichAuth
    //       this._enrichAuth.configure(options)
    //     }
    //   }
    // })
}

