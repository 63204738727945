import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import cognitoAuth from './cognito'
import enrichAuth from './auth'
import Axios from 'axios'
import TimeAgo from 'javascript-time-ago'
import moment from 'moment';
import { Icon } from 'ant-design-vue';
// import './assets/css/enrich-crm.css';

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$http = Axios;

Vue.use(Icon);

// English.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

// Create formatter (English).
const timeAgo = new TimeAgo('en-US')

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
});

Vue.filter('formatDateAgo', function(value) {
  if (value) {
      return timeAgo.format(Date.parse(value))
  }
});

new Vue({
  el: '#app',
  router,
  cognitoAuth,
  enrichAuth,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})

