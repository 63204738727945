// The imports we need from both aws-sdk and the cognito js library
import { Config, CognitoIdentityCredentials } from 'aws-sdk'
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js'

import config from '@/config'
import Axios from 'axios'

// We'll want to create a class for CognitoAuth which will
// contain all the methods we will need within our App
export default class CognitoAuth {
  // The constructor for this class will initialize our userSession
  // as null
  constructor () {
    this.userSession = null
  }


  isAuthenticated (cb) {
    console.log('start isAuthenticated');
    let cognitoUser = this.getCurrentUser()
    if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
        if (err) {
          console.log('isAuthenticated, err.message' + err.message);
            return cb(err, false)
        }
        console.log('cognito: isAuthenticated: success');
        return cb(session, true)
        })
    } else {
      console.log('isAuthenticated, null cognitoUser');
      cb(null, false)
    }
  }

  // this will set up our app to use cognito to use
  // the user pool that we'll be creating later on
  configure (config) {
    if (typeof config !== 'object' || Array.isArray(config)) {
        throw new Error('[CognitoAuth error] valid option object required')
    }
    this.userPool = new CognitoUserPool({
        UserPoolId: config.UserPoolId,
        ClientId: config.ClientId
    })
    Config.region = config.region
    Config.credentials = new CognitoIdentityCredentials({
        IdentityPoolId: config.IdentityPoolId
    })
    this.options = config
  }

  // a signup function which will allow new people
  // to create an account in our app
  signup(username, email, pass, cb) {
    let attributeList = [
        new CognitoUserAttribute({
            Name: 'email',
            Value: email
        })
    ]

    this.userPool.signUp(username, pass, attributeList, null, cb)
  }

  // a change password function allows user to change their password
  changePassword(username, oldPassword, newPassword, cb) {
  
    // let authenticationData = { Username: username, Password: oldPassword }
    // let authenticationDetails = new AuthenticationDetails(authenticationData)
    let userData = { Username: username, Pool: this.userPool }
    let cognitoUser = new CognitoUser(userData)
    console.log('In changePassword');

    cognitoUser.changePassword(oldPassword, newPassword, function(err, result) {
      if (err) {
        console.log('changePassword: err.message: ' + err.message);
        cb(null, err);
        return;
      }
        console.log('changePassword: success');
        cb(result);
    });

      
    //   {
    //   onSuccess: function (result) {
    //     console.log('changePassword: success');
    //       cb(null, result)
    //   },
    //   onFailure: function (err) {
    //     console.log('changePassword: err.message: ' + err.message);
    //     cb(err);
    //   }

    // });
    }

  // a forgot password function allows user to request a password change
  forgotPassword(username, cb) {
  
    // let authenticationData = { Username: username, Password: oldPassword }
    // let authenticationDetails = new AuthenticationDetails(authenticationData)
    let userData = { Username: username, Pool: this.userPool }
    let cognitoUser = new CognitoUser(userData)
    console.log('In forgotPassword');

    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        console.log('forgotPassword: success');
        cb(null, result)
      },
      onFailure: function (err) {
        console.log('forgotPassword: failure');
        console.log('forgotPassword: failure, code: ' + err.code);
        console.log('forgotPassword: failure, name: ' + err.name);
        console.log('forgotPassword: failure, message: ' + err.message);

          cb(err);
      },
      inputVerificationCode() { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
        // var verificationCode = prompt('Please input verification code ', '');
        // var newPassword = prompt('Enter new password ', '');
        // cognitoUser.confirmPassword(verificationCode, newPassword, this);
        console.log('forgotPassword: inpuitVerificationCode');
      }
    });

  }

  // 
  // completeNewPasswordChallenge(cognitoUser, newPassword, cb) {
  completeNewPasswordChallenge(cognitoUser, newPassword, cb) {
    // completeNewPasswordChallenge(cognitoUser, newPassword, userAttributes, cb) {
  
    // let authenticationData = { Username: username, Password: oldPassword }
    // let authenticationDetails = new AuthenticationDetails(authenticationData)
    //let userData = { Username: username, Pool: this.userPool }
    //let cognitoUser = new CognitoUser(userData)
    console.log('In completeNewPasswordChallenge');
    //let clientMetadata = {"Session": this.getCurrentUser.getSession};
    //export type ClientMetadata = { [key: string]: string } | undefined;


    // let attributes = {"email": userAttributes['email'], 
    //                   "name": cognitoUser.username};
    console.log('cognito: cognitoUser: ' + JSON.stringify(cognitoUser))
    let attributes = {"name": cognitoUser.username};
    // console.log('cognito: completeNewPasswordChallenge, attributes: ' + attributes)


    // console.log('cognito: completeNewPasswordChallenge, username: ' + username);
    // console.log('cognito: completeNewPasswordChallenge, pass: ' + pass);
    // let authenticationData = { Username: username, Password: pass }
    // console.log('cognito: completeNewPasswordChallenge new AuthenticationDetails');
    // let authenticationDetails = new AuthenticationDetails(authenticationData)
    // console.log('cognito: completeNewPasswordChallenge set userData');
    // let userData = { Username: username, Pool: this.userPool }
    // console.log('cognito: new CognitoUser: ' + JSON.stringify(userData));
    // console.log('cognito: new CognitoUser');
    // let cognitoUser = new CognitoUser(userData)
    // console.log('cognito: cognitoUser: ' + JSON.stringify(cognitoUser));
    // console.log('cognito: this.userAtributes: ' + JSON.stringify(this.userAtributes));


    // let cognitoUser = new CognitoUser(userData)

    //userAttributes.delete('name');
    cognitoUser.completeNewPasswordChallenge(newPassword, attributes, {
      onSuccess: function (result) {
        console.log('completeNewPasswordChallenge: success');
        cb(result)
      },
      onFailure: function (err) {
        console.log('completeNewPasswordChallenge: failure');
        console.log('completeNewPasswordChallenge: failure, code: ' + err.code);
        console.log('completeNewPasswordChallenge: failure, name: ' + err.name);
        console.log('completeNewPasswordChallenge: failure, message: ' + err.message);

          cb(null, err);
      }
    });

    // cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, function(err, result) {
    //   if (err) {
    //     console.log('completeNewPasswordChallenge: err.message: ' + err.message);
    //     cb(null, err);
    //     return;
    //   }
    //     console.log('completeNewPasswordChallenge: success');
    //     cb(result);
    // });

  }

  // a function that will allow existing users to
  // authenticate with our application
  authenticate (username, pass, cb) {
    console.log('cognito: start authenticate, username: ' + username);
    console.log('cognito: start authenticate, pass: ' + pass);
    let authenticationData = { Username: username, Password: pass }
    console.log('cognito: new AuthenticationDetails');
    let authenticationDetails = new AuthenticationDetails(authenticationData)
    console.log('cognito: set userData');
    let userData = { Username: username, Pool: this.userPool }
    // console.log('cognito: new CognitoUser: ' + JSON.stringify(userData));
    console.log('cognito: new CognitoUser');
    let cognitoUser = new CognitoUser(userData)

    console.log('about to authenticateUser');

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          console.log('cognito authenticateUser: success');
          Object.keys(result).forEach((prop)=> console.log(prop));
            var logins = {}
            logins['cognito-idp.' + config.region + '.amazonaws.com/' + config.IdentityPoolId] = result.getIdToken().getJwtToken()
            Axios.defaults.headers.common['Authorization'] = result.getIdToken().getJwtToken()
            
            Config.credentials = new CognitoIdentityCredentials({
                IdentityPoolId: config.IdentityPoolId,
                Logins: logins
            })
            console.log('cognito authenticateUser: (null, result)')
            cb(null, result)
        },
        onFailure: function (err) {
          console.log('cognito authenticateUser: failure');
          console.log('cognito authenticateUser: failure, code: ' + err.code);
          console.log('cognito authenticateUser: failure, name: ' + err.name);
          console.log('cognito authenticateUser: failure, message: ' + err.message);
          console.error('err: ' + Object.keys(err).forEach((prop)=> console.log(prop)));

            cb(err);
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          console.log('New Password Is Required')
          console.log('userAttributes...')
            Object.keys(userAttributes).forEach((prop)=> console.log(prop));
            console.log('requiredAttributes...')
            Object.keys(requiredAttributes).forEach((prop)=> console.log(prop));
            cb(null, null, cognitoUser, userAttributes)
          }
    })

  }

// a helper function that allows us to
  // get the information for the current user
  getCurrentUser () {
    return this.userPool.getCurrentUser()
  }

  // a function that allows us to confirm newly
  // registered users of our app
  confirmRegistration (username, code, cb) {
    let cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool
    })
    cognitoUser.confirmRegistration(code, true, cb)
  }

  // does what it says on the tin, allows users
  // to logout if they are already logged in
  logout () {
    this.getCurrentUser().signOut()
    delete Axios.defaults.headers.common['Authorization']
  }

  // Retrieve the users current token if they have
  // a session, otherwise returns null
  getIdToken (cb) {
    if (this.getCurrentUser() == null) {
        return cb(null, null)
    }
    this.getCurrentUser().getSession((err, session) => {
        if (err) return cb(err)
        if (session.isValid()) {
        return cb(null, session.getIdToken().getJwtToken())
        }
        cb(Error('Session is invalid'))
    })
  }

}

// This installed CognitoAuth into our Vue instance
CognitoAuth.install = function (Vue, options) {
  Object.defineProperty(Vue.prototype, '$cognitoAuth', {
    get () { return this.$root._cognitoAuth }
  })

  Vue.mixin({
    beforeCreate () {
      if (this.$options.cognitoAuth) {
        this._cognitoAuth = this.$options.cognitoAuth
        this._cognitoAuth.configure(options)
      }
    }
  })
}